import { Box, FlexBox, Text } from '@codecademy/gamut';
import { GraphStatsAscendIcon } from '@codecademy/gamut-icons';
import { Carousel, HorizontalScrollMenu } from '@mono/brand';
import { useTracking } from '@mono/data-use-tracking';

import { MobileCard } from '../../styles';
import { CategoryData } from '../../types';
import { catalogHomePageName } from '../const';
import { LinkedHubCard } from './LinkedHubCard';

export type TopCategoriesProps = {
  categories: CategoryData[];
};

export const TopCategories: React.FC<TopCategoriesProps> = ({ categories }) => {
  const title = 'Trending subjects & languages';
  const tracking = {
    context: 'featured_hubs',
    page_name: catalogHomePageName,
  };

  const { trackUserClick } = useTracking();

  if (!categories.length) {
    return null;
  }

  return (
    <Box mb={32}>
      <Box display={{ _: 'none', xs: 'block' }}>
        <Carousel
          key="catalog-home-carousel-subjects-and-languages"
          onArrowClick={(props) =>
            trackUserClick({
              context: 'featured_hubs',
              page_name: catalogHomePageName,
              ...props,
            })
          }
          title={
            <>
              <GraphStatsAscendIcon size={24} mr={8} />
              <Text as="h2" variant="title-sm" pb={8}>
                {title}
              </Text>
            </>
          }
          options={{
            label: 'subjects and languages',
          }}
          items={categories.map((category) => (
            <FlexBox width="100%" py={4} pr={4}>
              <LinkedHubCard
                item={category}
                trackingData={{
                  ...tracking,
                }}
              />
            </FlexBox>
          ))}
        />
      </Box>
      <Box display={{ _: 'block', xs: 'none' }}>
        <Text as="h2" variant="title-sm" pb={8}>
          {title}
        </Text>
        <HorizontalScrollMenu>
          {categories.map((category, index) => (
            <MobileCard key={category.slug} pb={8}>
              <LinkedHubCard
                item={category}
                trackingData={{
                  ...tracking,
                  element: index.toString(),
                }}
              />
            </MobileCard>
          ))}
        </HorizontalScrollMenu>
      </Box>
    </Box>
  );
};
