import {
  Anchor,
  Box,
  Checkbox,
  FlexBox,
  InfoTip,
  Radio,
  Text,
  WithChildrenProp,
} from '@codecademy/gamut';
import { css, theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import {
  ConsumerCatalogContainer,
  ContainerDifficultyEnum,
} from '@mono/data-codegen/client/graphql-gateway/graphql';
import {
  careerPathTooltipDescription,
  certificationPathTooltipDescription,
  courseTooltipDescription,
  skillPathTooltipDescription,
} from '@mono/data-curriculum-cards';
import { useTracking } from '@mono/data-use-tracking';
import { ReactNode } from 'react';

import { catalogHomePageName } from '../../../const';
import { usePaginatedCatalogContext } from '../../context';
import { Duration, Price } from './types';

const StyledRadio = styled(Radio)(
  css({
    label: {
      py: 4,
      ml: 4,
    },
  })
);

const FieldGroup = styled(Box)`
  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors['navy-300']};
  }
`;

interface FilterSectionProps extends WithChildrenProp {
  headerText: string;
  headerElement?: ReactNode;
}

const FilterSection: React.FC<FilterSectionProps> = ({
  headerText,
  headerElement,
  children,
}) => {
  const labelID = `fg-${headerText.replace(/\s/g, '')}`;
  return (
    <FieldGroup pb={8} mt={16}>
      <FlexBox alignItems="center">
        <Text
          id={labelID}
          lineHeight="title"
          aria-label={`Filter by ${headerText}`}
          fontSize={14}
          as="h4"
        >
          {headerText}
        </Text>
        {headerElement}
      </FlexBox>
      <FlexBox
        as="fieldset"
        aria-labelledby={labelID}
        role="group"
        flexDirection="column"
      >
        {children}
      </FlexBox>
    </FieldGroup>
  );
};

interface FilterOption<T> {
  value: T;
  copy: string;
  trackingKey: string;
  tooltipText?: string;
}

const LevelFilters: React.FC = () => {
  const headerText = 'Level';
  const trackingContext = 'level_filter';
  const { trackUserClick } = useTracking();

  const options: FilterOption<ContainerDifficultyEnum>[] = [
    {
      value: ContainerDifficultyEnum.Beginner,
      copy: 'Beginner',
      trackingKey: 'beginner',
    },
    {
      value: ContainerDifficultyEnum.Intermediate,
      copy: 'Intermediate',
      trackingKey: 'intermediate',
    },
    {
      value: ContainerDifficultyEnum.Advanced,
      copy: 'Advanced',
      trackingKey: 'advanced',
    },
  ];

  const { selectedFilters, setSelectedFilters, setCurrPageNum, triggerFetch } =
    usePaginatedCatalogContext();

  const curr = selectedFilters.difficulty;

  const handleClick = (option: FilterOption<ContainerDifficultyEnum>) => {
    if (!curr.includes(option.value)) {
      trackUserClick({
        page_name: catalogHomePageName,
        context: trackingContext,
        target: option.trackingKey,
      });
      setSelectedFilters({
        ...selectedFilters,
        difficulty: [...curr, option.value],
      });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        difficulty: curr.filter((v) => v !== option.value),
      });
    }
    setCurrPageNum(1);
    triggerFetch();
  };

  return (
    <FilterSection headerText={headerText}>
      {options.map((option) => (
        <FlexBox alignItems="center" key={option.value}>
          <Checkbox
            htmlFor={option.value}
            checked={curr.includes(option.value)}
            onChange={() => handleClick(option)}
            label={option.copy}
            spacing="tight"
          />
        </FlexBox>
      ))}
    </FilterSection>
  );
};

const PriceFilters: React.FC = () => {
  const headerText = 'Price';
  const trackingContext = 'price_filter';
  const { trackUserClick } = useTracking();

  const options: FilterOption<Price>[] = [
    {
      value: Price.Free,
      copy: 'Free',
      trackingKey: 'free',
    },
    {
      value: Price.Paid,
      copy: 'Paid',
      trackingKey: 'paid',
    },
  ];

  const plansLink = (
    <Anchor
      onClick={() =>
        trackUserClick({
          page_name: catalogHomePageName,
          context: 'price_filter',
          target: 'view_plans',
        })
      }
      variant="standard"
      lineHeight="title"
      fontSize={14}
      href="/pricing"
      pl={8}
    >
      View plans
    </Anchor>
  );

  const { selectedFilters, setSelectedFilters, setCurrPageNum, triggerFetch } =
    usePaginatedCatalogContext();

  const curr = selectedFilters.price;

  const handleClick = (option: FilterOption<Price>) => {
    if (!curr.includes(option.value)) {
      trackUserClick({
        page_name: catalogHomePageName,
        context: trackingContext,
        target: option.trackingKey,
      });
      setSelectedFilters({
        ...selectedFilters,
        price: [...curr, option.value],
      });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        price: curr.filter((v) => v !== option.value),
      });
    }
    setCurrPageNum(1);
    triggerFetch();
  };

  return (
    <FilterSection headerText={headerText} headerElement={plansLink}>
      {options.map((option) => (
        <FlexBox alignItems="center" key={option.value}>
          <Checkbox
            htmlFor={option.value}
            checked={curr.includes(option.value)}
            onChange={() => handleClick(option)}
            label={option.copy}
            spacing="tight"
          />
        </FlexBox>
      ))}
    </FilterSection>
  );
};

const ContainerFilters: React.FC = () => {
  const headerText = 'Type';
  const trackingContext = 'type_filter';
  const { trackUserClick } = useTracking();

  const options: FilterOption<ConsumerCatalogContainer>[] = [
    {
      value: ConsumerCatalogContainer.CareerPath,
      copy: 'Career path',
      trackingKey: 'career',
      tooltipText: careerPathTooltipDescription,
    },
    {
      value: ConsumerCatalogContainer.SkillPath,
      copy: 'Skill path',
      trackingKey: 'skill',
      tooltipText: skillPathTooltipDescription,
    },
    {
      value: ConsumerCatalogContainer.ExternalCertificationPath,
      copy: 'Certification path',
      trackingKey: 'certification',
      tooltipText: certificationPathTooltipDescription,
    },
    {
      value: ConsumerCatalogContainer.Track,
      copy: 'Course',
      trackingKey: 'course',
      tooltipText: courseTooltipDescription,
    },
  ];

  const { selectedFilters, setSelectedFilters, setCurrPageNum, triggerFetch } =
    usePaginatedCatalogContext();

  const curr = selectedFilters.container;

  const handleClick = (option: FilterOption<ConsumerCatalogContainer>) => {
    if (!curr.includes(option.value)) {
      trackUserClick({
        page_name: catalogHomePageName,
        context: trackingContext,
        target: option.trackingKey,
      });
      setSelectedFilters({
        ...selectedFilters,
        container: [...curr, option.value],
      });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        container: curr.filter((v) => v !== option.value),
      });
    }
    setCurrPageNum(1);
    triggerFetch();
  };

  return (
    <FilterSection headerText={headerText}>
      {options.map((option) => (
        <FlexBox alignItems="center" key={option.value}>
          <Checkbox
            htmlFor={option.value}
            checked={curr.includes(option.value)}
            onChange={() => handleClick(option)}
            label={option.copy}
            spacing="tight"
          />
          {option.tooltipText && (
            <InfoTip
              alignment="top-right"
              placement="floating"
              info={option.tooltipText}
            />
          )}
        </FlexBox>
      ))}
    </FilterSection>
  );
};

const DurationFilters: React.FC = () => {
  const headerText = 'Average time to complete';
  const trackingContext = 'length_filter';
  const { trackUserClick } = useTracking();

  const options: FilterOption<Duration>[] = [
    {
      value: Duration.All,
      copy: 'All',
      trackingKey: 'all',
    },
    {
      value: Duration.XS,
      copy: 'Less than 5 hours',
      trackingKey: 'extra_small',
    },
    {
      value: Duration.S,
      copy: '5-10 hours',
      trackingKey: 'small',
    },
    {
      value: Duration.M,
      copy: '10-20 hours',
      trackingKey: 'medium',
    },
    {
      value: Duration.L,
      copy: '20-60 hours',
      trackingKey: 'large',
    },
    {
      value: Duration.XL,
      copy: '60+ hours',
      trackingKey: 'extra_large',
    },
  ];

  const { selectedFilters, setSelectedFilters, setCurrPageNum, triggerFetch } =
    usePaginatedCatalogContext();

  const curr = selectedFilters.duration;

  const handleClick = (option: FilterOption<Duration>) => {
    trackUserClick({
      page_name: catalogHomePageName,
      context: trackingContext,
      target: option.trackingKey,
    });
    setSelectedFilters({
      ...selectedFilters,
      duration: option.value,
    });
    setCurrPageNum(1);
    triggerFetch();
  };

  const ttcTooltip = (
    <InfoTip
      alignment="top-right"
      placement="floating"
      info="Average based on combined completion rates — individual pacing in lessons,
      projects, and quizzes may vary."
    />
  );

  return (
    <FilterSection headerText={headerText} headerElement={ttcTooltip}>
      <FlexBox role="radiogroup" flexDirection="column" aria-label={headerText}>
        {options.map((option) => (
          <StyledRadio
            htmlFor={option.value}
            checked={curr === option.value}
            onChange={() => handleClick(option)}
            label={option.copy}
            key={option.value}
          />
        ))}
      </FlexBox>
    </FilterSection>
  );
};

export const Filters: React.FC = () => {
  return (
    <>
      <LevelFilters />
      <PriceFilters />
      <ContainerFilters />
      <DurationFilters />
    </>
  );
};
