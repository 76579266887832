import { FlexBox, Text } from '@codecademy/gamut';
import { FilterIcon } from '@codecademy/gamut-icons';

import { FilterCountBadge } from '~/components/FilterCountBadge';

import { usePaginatedCatalogContext } from '../context';
import { ClearFiltersButton } from './ClearFiltersButton';
import { Filters } from './Filters';

export const DesktopMenu: React.FC = () => {
  const { filterCount } = usePaginatedCatalogContext();
  return (
    <FlexBox
      position="sticky"
      flexDirection="column"
      top={94}
      mt={40}
      mb={40}
      mr={8}
      maxHeight={{ md: 'calc(100vh - 104px)' }}
      overflow="auto"
    >
      <FlexBox alignItems="center">
        <FilterIcon size={24} />
        <Text ml={8} as="h3" fontWeight="bold" fontSize="large">
          Filters
        </Text>
        {filterCount > 0 && <FilterCountBadge count={filterCount} ml={8} />}
        <ClearFiltersButton />
      </FlexBox>
      <FlexBox width="80%" flexDirection="column">
        <Filters />
      </FlexBox>
    </FlexBox>
  );
};
