import {
  ConsumerCatalogContainer,
  ContainerDifficultyEnum,
} from '@mono/data-codegen/client/graphql-gateway/graphql';

export enum Duration {
  All = 'durationHoursAll',
  XS = 'durationHoursXS',
  S = 'durationHoursS',
  M = 'durationHoursM',
  L = 'durationHoursLG',
  XL = 'durationHoursXL',
}

export enum Price {
  Free = 'priceFree',
  Paid = 'pricePaid',
}

export type SelectedFilters = {
  difficulty: ContainerDifficultyEnum[];
  price: Price[];
  container: ConsumerCatalogContainer[];
  duration: Duration;
};
