import { Anchor } from '@codecademy/gamut';
import { CurriculumCard, EnrollmentStatus } from '@mono/brand';
import { CourseDifficulty } from '@mono/data-codegen/client/graphql-gateway/graphql';
import { useTracking } from '@mono/data-use-tracking';
import { getLearnPath } from '@mono/util-url';

import { catalogHomePageName } from '../const';
import { ExternalOrgCatalogItem } from '../types';

export type ExternalOrgCardProps = {
  item: ExternalOrgCatalogItem;
};

/*
 * Component adapted from the original that was used on /catalog/all and /catalog/org/<orgid>
 * We're keeping consistency with the old designs and quirks like the 'path' text displaying for both Tracks and Paths alike.
 */
export const ExternalOrgCard: React.FC<ExternalOrgCardProps> = ({ item }) => {
  const {
    slug,
    title,
    lessonCount,
    articleCount,
    quizCount,
    enrollmentStatus,
    containerType,
  } = item;

  const contentIsPath = containerType === 'Path';

  // converting 'None' to undefined for legacy brand card prop
  const progressState =
    enrollmentStatus !== EnrollmentStatus.None ? enrollmentStatus : undefined;

  const { trackUserClick } = useTracking();

  const href = getLearnPath(slug, contentIsPath ? 'Path' : 'Track');

  return (
    <Anchor
      href={href}
      variant="interface"
      onClick={() => {
        trackUserClick({
          page_name: catalogHomePageName,
          context: 'external_org_catalog',
          target: `${contentIsPath ? 'path' : 'course'}-card`,
          slug,
          href,
        });
      }}
    >
      <CurriculumCard
        difficulty={CourseDifficulty.Beginner} // difficulty does not show on these cards, copied this from legacy component
        scope={{
          lesson: lessonCount ?? 0,
          article: articleCount ?? 0,
          quiz: quizCount ?? 0,
        }}
        headingLevel="h3"
        progressState={progressState}
        title={title || ''}
        text="path" // [copied code comment]: "Ext Org Path/Track cards need to just say 'Path'"
        showAltSubtitle
        footerTextVariant="inProgress"
      />
    </Anchor>
  );
};
