import {
  ConsumerCatalogContainer,
  ContainerDifficultyEnum,
  ContainerOrderBy,
  ContainerOrderByKey,
  OrderByDirection,
  PaginatedCatalogQueryVariables,
} from '@mono/data-codegen/client/graphql-gateway/graphql';
import { ParsedUrlQuery } from 'querystring';

import { CATALOG_PAGE_SIZE } from '../../const';
import { Duration, Price, SelectedFilters } from '../FilterMenus/Filters/types';
import { SortBy } from '../SortByDropdown/types';

export const getFilterCount = (state: SelectedFilters): number =>
  state.difficulty.length +
  state.price.length +
  state.container.filter((c) => {
    // Always treat ExternalCourses as 'Course' selection, so dont double count it as a filter
    return c !== ConsumerCatalogContainer.ExternalCourse;
  }).length +
  (state.duration === Duration.All ? 0 : 1);

export const getQueryVariables = (
  filters: SelectedFilters,
  sortBy: SortBy,
  page: number
): PaginatedCatalogQueryVariables => {
  return {
    difficulty: getDifficulty(filters.difficulty),
    proExclusive: getProExclusive(filters.price),
    consumerCatalogContainerTypes: getContainerTypes(filters.container),
    minDurationHours: getMinDurationHours(filters.duration),
    maxDurationHours: getMaxDurationHours(filters.duration),
    paginate: { perPage: CATALOG_PAGE_SIZE, page },
    order: getOrder(sortBy),
  };
};

const getDifficulty = (difficulty: ContainerDifficultyEnum[]) => {
  // sorting maintains results caching
  return difficulty.length ? difficulty.sort() : undefined;
};

export const getProExclusive = (price: Price[]) => {
  if (price.length === 2 || price.length === 0) {
    // 'all' prices
    return undefined;
  }
  return price.includes(Price.Paid);
};

export const getContainerTypes = (containers: ConsumerCatalogContainer[]) => {
  const args = containers.length
    ? [...containers]
    : [
        // DO NOT pass Journey as a container type
        // Since some paths that are career paths have been converted to journeys but still show up on the catalog under path
        // and then redirect you to the journey page.
        ConsumerCatalogContainer.CareerPath,
        ConsumerCatalogContainer.SkillPath,
        ConsumerCatalogContainer.ExternalCertificationPath,
        ConsumerCatalogContainer.Track,
      ];

  // treating both Tracks and ExternalCourses as 'Course' selection
  if (args.includes(ConsumerCatalogContainer.Track)) {
    args.push(ConsumerCatalogContainer.ExternalCourse);
  }

  // sorting maintains results caching
  return args.sort();
};

const durationMinMax: Record<Duration, { min?: number; max?: number }> = {
  [Duration.All]: {},
  [Duration.XS]: { max: 5 },
  [Duration.S]: { min: 5, max: 10 },
  [Duration.M]: { min: 10, max: 20 },
  [Duration.L]: { min: 20, max: 60 },
  [Duration.XL]: { min: 60 },
};

const getMinDurationHours = (duration: Duration) => {
  return durationMinMax[duration]?.min;
};

const getMaxDurationHours = (duration: Duration) => {
  return durationMinMax[duration]?.max;
};

export const getOrder = (sortBy: SortBy): ContainerOrderBy => {
  const queryOrderBy: Record<SortBy, ContainerOrderByKey> = {
    [SortBy.POPULAR]: ContainerOrderByKey.RecentEnrollmentCount,
    [SortBy.RECENT]: ContainerOrderByKey.CreatedAt,
  };

  return {
    by: queryOrderBy[sortBy],
    direction: OrderByDirection.Desc,
  };
};

const queryParamToFilterValuesMap: Record<string, Record<string, string>> = {
  difficulty: {
    beginner: ContainerDifficultyEnum.Beginner,
    intermediate: ContainerDifficultyEnum.Intermediate,
    advanced: ContainerDifficultyEnum.Advanced,
  },
  price: {
    free: Price.Free,
    paid: Price.Paid,
  },
  container: {
    'career-path': ConsumerCatalogContainer.CareerPath,
    'skill-path': ConsumerCatalogContainer.SkillPath,
    'certification-path': ConsumerCatalogContainer.ExternalCertificationPath,
    course: ConsumerCatalogContainer.Track,
  },
  duration: {
    extra_small: Duration.XS,
    small: Duration.S,
    medium: Duration.M,
    large: Duration.L,
    extra_large: Duration.XL,
  },
};

export const queryParamToFilterKeyMap: Record<string, string> = {
  level: 'difficulty',
  price: 'price',
  type: 'container',
  length: 'duration',
};

export const getSelectedFiltersDefault = (
  urlQueryParams?: ParsedUrlQuery
): SelectedFilters => {
  const initialFilters: SelectedFilters = {
    difficulty: [],
    price: [],
    container: [],
    duration: Duration.All,
  };

  if (!urlQueryParams) return initialFilters;

  Object.entries(urlQueryParams).forEach(([queryParam, value]) => {
    if (queryParam in queryParamToFilterKeyMap && value) {
      const filterKey = queryParamToFilterKeyMap[queryParam];
      const selectedFiltersState = value
        .toString()
        .split(',')
        .filter((f) => f in queryParamToFilterValuesMap[filterKey])
        .map((f) => queryParamToFilterValuesMap[filterKey][f]);

      switch (filterKey) {
        case 'difficulty':
          initialFilters.difficulty =
            selectedFiltersState as ContainerDifficultyEnum[];
          break;
        case 'price':
          initialFilters.price = selectedFiltersState as Price[];
          break;
        case 'container':
          initialFilters.container =
            selectedFiltersState as ConsumerCatalogContainer[];
          break;
        case 'duration':
          initialFilters.duration = selectedFiltersState[0] as Duration;
          break;
        default:
          break;
      }
    }
  });

  return initialFilters;
};
