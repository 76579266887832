import { gql } from '@mono/data-codegen/client/graphql-gateway';

gql(`
  fragment catalogCategoryData on Category {
    type: __typename
    slug
    categoryType: classification
    title
  }
`);

export const paginatedCatalogQuery = gql(`
  query paginatedCatalog(
	$difficulty: [ContainerDifficultyEnum!],
	$proExclusive: Boolean,
	$consumerCatalogContainerTypes: [ConsumerCatalogContainer!],
	$minDurationHours: Int,
	$maxDurationHours: Int,
	$order: ContainerOrderBy,
	$paginate: PaginationInput!,
  $skipEnrollmentStatus: Boolean = false
  ) {
	paginatedContainers(
		difficulty: $difficulty,
		proExclusive: $proExclusive,
		consumerCatalogContainerTypes: $consumerCatalogContainerTypes,
		minDurationHours: $minDurationHours,
		maxDurationHours: $maxDurationHours,
		order: $order,
		paginate: $paginate
	) {
	  collection {
		  ...on Track {
			  ...rawTrackCardData
		  }
		  ...on Path {
			  ...rawPathCardData
		  }
		  ...on ExternalCourse {
			  ...rawExtCourseCardData
		  }
      ...on ExternalPath {
        ...rawExtPathCardData
      }
	  }
	  paginationMetadata {
		  totalPages
		  totalResults
	  }
	}
  }
`);

export const catalogHomeQuery = gql(`
  query catalogHome {
	me {
	  ...catalogUserData
    totalEnrollments
	}

    categories(
      classification: ["language", "subject"]
      filter: {
        operator: OR
        releasedPathCount: { gt: 0 }
        releasedTrackCount: { gt: 0 }
        releasedExternalCourseCount: { gt: 0 }
      }
      order: { by: TITLE, direction: ASC }
    ) {
      ...catalogCategoryData
    }

    topCategories: paginatedCategories(
      paginate: { page: 1, perPage: 10 }
      order: { by: VISIT_COUNT, direction: DESC }
    ) {
      collection {
	    ...catalogCategoryData
      }
    }

    certPrepContent: externalPaths {
      id
      type: __typename
    }
  }
`);

export const externalOrgContentsQuery = gql(`
  query externalOrgContents($organizationId: [String!]) {
    paginatedContainers(
      organizationId: $organizationId,
      paginate: { page: 1, perPage: 1000 }
    ) {
      collection {
        ...on Path {
          organizationId
          id
          title
          slug
          articleCount: contentItemCount(contentItemType: Article)
          lessonCount: contentItemCount(contentItemType: Lesson)
          quizCount: contentItemCount(contentItemType: Quiz)
          enrollmentStatus
          type: __typename
        }
        ...on Track {
          organizationId
          id
          title
          slug
          articleCount: contentItemCount(contentItemType: Article)
          lessonCount: contentItemCount(contentItemType: Lesson)
          quizCount: contentItemCount(contentItemType: Quiz)
          enrollmentStatus
          type: __typename
        }
        ...on ExternalCourse {
          type: __typename
        }
        ...on ExternalPath {
          type: __typename
        }
      }
    }
  }
`);
