import { Anchor, Badge, Box, FlexBox, Text } from '@codecademy/gamut';
import { MiniArrowRightIcon } from '@codecademy/gamut-icons';
import { fontFamily, theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { useTracking } from '@mono/data-use-tracking';

import { catalogHomePageName } from '../const';

export type ContentCounts = {
  courseCount: number;
  skillPathCount: number;
  careerPathCount: number;
  certificationPathCount: number;
};

export type HubSpotlightProps = {
  contentCounts?: ContentCounts | null;
  eyebrow: string;
  header: string;
  description: string;
  slug: string;
  cta: {
    text: string;
    href: string;
  };
  imageSrc: string;
};

const Image = Box.withComponent('img');

const Bold = styled.span`
  font-family: ${fontFamily.base};
  font-size: 18px;
  font-weight: bold;
`;

export const HubSpotlight: React.FC<HubSpotlightProps> = (props) => {
  const { contentCounts, eyebrow, header, description, slug, cta, imageSrc } =
    props;

  const { trackUserClick } = useTracking();

  return (
    <Box>
      <FlexBox
        borderColor="border-primary"
        borderWidth={1}
        borderStyle="solid"
        borderRadius="xl"
        justifyContent="space-between"
        ml={12}
        mb={12}
        px={{ _: 32, sm: 48 }}
        py={24}
        gap={{ sm: 40, xl: 130 as 0 }}
        boxShadow={`-12px 12px ${theme.colors['background-current']}, -12px 12px 0 1px ${theme.colors.navy}`}
      >
        <FlexBox flexDirection="column">
          <Badge mb={8}>{eyebrow}</Badge>
          <Text as="h2" fontSize={34} mb={8}>
            {header}
          </Text>
          {contentCounts && (
            <FlexBox
              as="ul"
              listStyleType="none"
              flexDirection="row"
              flexWrap="wrap"
              rowGap={8}
              columnGap={16}
              mb={8}
              p={0}
            >
              {contentCounts.courseCount > 0 && (
                <Box fontSize={14} fontFamily="accent" as="li">
                  <Bold>{contentCounts.courseCount}</Bold> Courses
                </Box>
              )}
              {contentCounts.skillPathCount > 0 && (
                <Box fontSize={14} fontFamily="accent" as="li">
                  <Bold>{contentCounts.skillPathCount}</Bold> Skill Paths
                </Box>
              )}
              {contentCounts.careerPathCount > 0 && (
                <Box fontSize={14} fontFamily="accent" as="li">
                  <Bold>{contentCounts.careerPathCount}</Bold> Career Paths
                </Box>
              )}
              {contentCounts.certificationPathCount > 0 && (
                <Box fontSize={14} fontFamily="accent" as="li">
                  <Bold>{contentCounts.certificationPathCount}</Bold>
                  &nbsp;Certification Paths
                </Box>
              )}
            </FlexBox>
          )}
          <Text mb={16}>{description}</Text>
          <Anchor
            variant="standard"
            href={cta.href}
            onClick={() => {
              trackUserClick({
                page_name: catalogHomePageName,
                context: 'hub_spotlight',
                target: 'hub_card',
                slug,
                href: cta.href,
              });
            }}
          >
            <FlexBox alignItems="center">
              <Text fontWeight="bold" mr={4}>
                {cta.text}
              </Text>
              <MiniArrowRightIcon />
            </FlexBox>
          </Anchor>
        </FlexBox>
        <Box minWidth={{ sm: 147, md: 200 }}>
          <Image
            src={imageSrc}
            display={{ _: 'none', sm: 'block' }}
            width="100%"
            height="100%"
            alt=""
            aria-hidden
          />
        </Box>
      </FlexBox>
    </Box>
  );
};
