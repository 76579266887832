import { Box, FlexBox, Text } from '@codecademy/gamut';
import { Carousel, HorizontalScrollMenu } from '@mono/brand';
import { CurriculumCardData } from '@mono/data-curriculum-cards';
import { useTracking } from '@mono/data-use-tracking';
import { VariableCurriculumCard } from '@mono/ui-components';

import { MobileCard } from '../../styles';
import { catalogHomePageName } from '../const';

interface FeaturedContainersProps {
  containers: CurriculumCardData[];
}

export const FeaturedContainers: React.FC<FeaturedContainersProps> = ({
  containers,
}) => {
  const { trackUserClick } = useTracking();
  const title = 'Featured courses and paths';
  const trackingBase = {
    page_name: catalogHomePageName,
    context: 'featured_containers',
  };

  if (!containers.length) {
    return null;
  }

  return (
    <Box mb={32}>
      <Box display={{ _: 'none', xs: 'block' }}>
        <Carousel
          key="featured-containers"
          onArrowClick={(props) =>
            trackUserClick({
              ...trackingBase,
              ...props,
            })
          }
          title={
            <Text as="h2" variant="title-sm" pb={8}>
              {title}
            </Text>
          }
          options={{
            label: title,
          }}
          items={containers.map((c) => (
            <FlexBox width="100%" pt={4} pb={8} key={c.id}>
              <VariableCurriculumCard content={c} trackingData={trackingBase} />
            </FlexBox>
          ))}
        />
      </Box>
      <Box display={{ _: 'block', xs: 'none' }}>
        <Text as="h2" variant="title-sm" pb={8}>
          {title}
        </Text>
        <HorizontalScrollMenu>
          {containers.map((c) => (
            <MobileCard key={c.id} pb={8}>
              <VariableCurriculumCard content={c} trackingData={trackingBase} />
            </MobileCard>
          ))}
        </HorizontalScrollMenu>
      </Box>
    </Box>
  );
};
